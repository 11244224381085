import React from "react"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Calendar from "../assets/images/icon/calendar.inline.svg"

const CurriculumVitae = () => {
  return (
    <Layout>
      <Seo title="Curriculum Vitae Rianne" />
      <BackgroundImageWrapper height="403px" position="center" hero="hero6">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-150px flex justify-center flex-col text-center relative">
          <h1 className="text-4xl font-bold text-white mb-10px">
            Curriculum Vitae
          </h1>
          <p className="text-22px font-normal text-white">
            Rianne Hillenaar-Cöp
          </p>
        </div>
      </BackgroundImageWrapper>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <div className="relative">
            <div className="flex mb-15px">
              <div className="order-3 basis-1/2 flex-grow p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-start items-center h-full">
                  september 1988
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left-right order-1 basis-1/2 flex-grow">
                <p className="text-lg text-primary block md:hidden">
                  september 1988
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Rechtenstudie
                </h4>
                <p className="text-lg text-primary mb-30px">
                  Universiteit Nijmegen
                </p>
              </div>
            </div>

            <div className="flex mb-15px">
              <div className="basis-1/2 order-1 p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-end items-center h-full">
                  juli 1993
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left basis-1/2 flex-grow order-3">
                <p className="text-lg text-primary block md:hidden">
                  juli 1993
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Procesjurist bij FNV
                </h4>
                <p className="text-lg text-primary mb-30px">
                  Arbeidsrecht en Sociaal
                  <br /> Zekerheidsrecht
                </p>
              </div>
            </div>

            <div className="flex mb-15px">
              <div className="basis-1/2 order-3 p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-start items-center h-full">
                  januari 2003
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left-right basis-1/2 flex-grow order-1">
                <p className="text-lg text-primary block md:hidden">
                  januari 2003
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Advocaat in Gouda
                </h4>
                <p className="text-lg text-primary mb-30px">
                  Van Riessen Advocaten
                  <br />
                  Arbeidsrecht en Familierecht
                </p>
              </div>
            </div>

            <div className="flex mb-15px">
              <div className="basis-1/2 order-1 p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-end items-center h-full">
                  februari 2010
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left basis-1/2 flex-grow order-3">
                <p className="text-lg text-primary block md:hidden">
                  februari 2010
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Certificering Register Mediator
                </h4>
                <p className="text-lg text-primary mb-30px">
                  start gecombineerde praktijk
                  <br />
                  als advocaat en mediator
                </p>
              </div>
            </div>

            <div className="flex mb-15px">
              <div className="basis-1/2 order-3 p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-start items-center h-full">
                  januari 2012
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left-right basis-1/2 flex-grow order-1">
                <p className="text-lg text-primary block md:hidden">
                  januari 2012
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Advocaat-Mediator in Nijmegen
                </h4>
                <p className="text-lg text-primary mb-30px">
                  2BW Advocaten
                  <br />
                  Arbeidsrecht en Familierecht
                </p>
              </div>
            </div>

            <div className="flex mb-15px">
              <div className="basis-1/2 order-1 p-5 hidden md:block">
                <div className="text-sm text-primary flex justify-end items-center h-full">
                  februari 2016
                </div>
              </div>
              <div className="flex items-center order-1 md:order-2 z-10">
                <div className="p-1 w-8 h-8 flex justify-center items-center bg-primary rounded-full mx-5">
                  <div className="w-3 h-3">
                    <Calendar />
                  </div>
                </div>
              </div>
              <div className="p-5 rounded-sm bg-light-gray text-center arrow-left basis-1/2 flex-grow order-3">
                <p className="text-lg text-primary block md:hidden">
                  februari 2016
                </p>
                <h4 className="text-xl text-primary mt-10 mb-5px font-semibold">
                  Oprichting Eerstmediation.nl
                </h4>
                <p className="text-lg text-primary mb-30px">
                  eigen kantoor in Cuijk
                </p>
              </div>
            </div>
            <div className="vertical-line-left md:vertical-line-mid absolute bg-primary w-3px top-100px bottom-100px"></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CurriculumVitae
